import React from 'react';
import {connect} from "react-redux";
import {push} from "connected-react-router";
import './Pricing.css'

const Pricing = ({push}) => {
    return (
        <div className={'pricing'}>
            <div className={'subpage-header'}>HINNASTAMISE PÕHIMÕTTED</div>
            <div className={'pricing-text-main pricing-text'}>
                Sissenõudmisteenus Eestis
            </div>
            <div className={'price-section'}>
                <div>Stardiraha</div>
                <div>20 € + KM</div>
            </div>
            <div className={'pricing-text'}>
                Stardiraha tasumine käivitab võla kohtuvälise sissenõudmise Eestis (edaspidi võlamenetlus) ja sellega loetakse leping sõlmituks. Stardiraha katab võlamenetluse alustamisega seotud esmased kulud. Kõik võlamenetlusega seotud kulud vastavalt seaduses sätestatud piirmääradele (edaspidi sissenõudmiskulud) lisame võlanõudele ning nõuame sisse võlgnikult.
            </div>
            <div className={'price-section'}>
                <div>Tulemustasu sissenõutud summalt</div>
                <div className={'no-wrap'}>20% + KM</div>
            </div>
            <div className={'pricing-text'}>
                Klient tasub Krediidivalvele tulemustasu järgmistel juhtudel:<br/><br/>
                • Krediidivalve menetlusse antud võlgnevuse katteks laekunud summalt, sh ka laekunud sissenõudmiskuludelt;
                <br/><br/>
                • kui laekumine on toimunud enne nõude võlanõudena registreerimist ja laekumine toimub Krediidivalve töö tulemusel.
            </div>
            <div className={'pricing-text'}>
                Võlgnikult laekunud summad arvestatakse esmajärjekorras seadusjärgsete sissenõudmiskulude katteks.
            </div>
            <div className={'pricing-text-main pricing-text'}>
                Õigusabiteenus Eestis
            </div>
            <div className={'price-section'}>
                <div className={'price-section-description'}>Maksekäsu kiirmenetluse avalduse koostamine ja esitamine</div>
                <div className={'no-wrap'}>120 € + KM</div>
            </div>
            <div className={'price-section'}>
                <div>Täitmisavalduse koostamine ja esitamine</div>
                <div className={'no-wrap'}>80 € + KM</div>
            </div>
            <div className={'price-section'}>
                <div className={'price-section-description'}>Nõudeavalduse koostamine ja esitamine pankrotimenetluses</div>
                <div className={'no-wrap'}>240 € + KM</div>
            </div>
            <div className={'price-section'}>
                <div>Õigusabikulud</div>
                <div className={'no-wrap'}>160 €/h + KM</div>
            </div>
            <button className={'btn forward-button subpage-bottom-button'} type={'button'} onClick={() => {
                push('/')
            }}>
                ALUSTAN MENETLUST
            </button>
        </div>
    )
};

export default connect(null, {push})(Pricing);
