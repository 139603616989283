import React from 'react';
import logo from "../asset/Krediidivalve Copy.svg";
import {connect} from "react-redux";
import {push} from "connected-react-router";

const Logo = ({push}) => {
    return (
        <img alt={''} src={logo} onClick={() => push('/')}
             className="Logo-x"/>
    )
};

export default connect(null, {push})(Logo);
