import React from 'react';
import progress1 from "../../asset/Timeline.svg";
import progress2 from "../../asset/Timeline-2.svg";
import progress3 from "../../asset/Timeline-3.svg";
import progress4 from "../../asset/Timeline-4.svg";
import progress5 from "../../asset/Timeline-5.svg";

export const ProgressBar = ({index}) => {
    const timelines = [progress1, progress2, progress3, progress4, progress5];

    return (
        <img alt={''} src={timelines[index]} className={"progress-bar"}/>
    )
};
