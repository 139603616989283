import React from 'react';

export const FormHeader = ({icon, text}) => {
    return (
        <div className={"section-header"}>
            <img alt={''} className={'section-header-icon'} src={icon}/>
            <span className={'section-header-title'}>{text}</span>
        </div>
    )
};
