import React from 'react';
import {Form, Formik} from "formik";
import {CheckBoxField, SelectField, SimpleField} from "../form/simple-field";
import newUser from "../../asset/andmed.svg"
import address from "../../asset/aadress.svg"
import contact from "../../asset/sidevahendid.svg"
import {FormHeader} from "./FormHeader";
import './Form.css';
import {push} from "connected-react-router";
import {connect} from "react-redux";
import {ProgressBar} from "../progress-bar/ProgressBar";
import {getJsonValue, getPermJsonValue, getValue, setJsonValue, setPermJsonValue} from "../../store/session-storage-util";


const ReporterForm = ({push}) => {
    const savedValues = getPermJsonValue('reporterForm') || getJsonValue('reporterForm');

    const initialValues = savedValues && savedValues.rememberMe ?
        savedValues :
        {
            person: {
                personType: 'BUSINESS',
                registrationNr: ''
            },
            contacts: {
                phoneNr: '+372 '
            },
            representative: {
                phoneNr: '+372 ',
                representType: 'Seadus',
            },
            isRepresent: false,
            rememberMe: false,
        };

    return (
        <div>
            <ProgressBar index={1}/>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={(values) => {
                    if (values.rememberMe) {
                        setPermJsonValue('reporterForm', values);
                    } else {
                        setJsonValue('reporterForm', values);
                    }
                    if (getValue('editingReporter')) {
                        push('/menetlustaotlus');
                    } else {
                        push('/menetlustaotlus/nõuded');
                    }
                }}
            >
                {({
                      isSubmitting, values
                  }) => {
                    return (
                        <Form>
                            <FormHeader icon={newUser} text={'VÕLAUSALDAJA ANDMED'}/>
                            <SelectField name={'person.personType'} label={'Isik*'}
                                         options={[
                                             {code: 'BUSINESS', value: 'Juriidiline isik'},
                                             {code: 'PRIVATE', value: 'Eraisik'}
                                         ]}/>
                            {values.person.personType === 'BUSINESS' ?
                                <>
                                    <SimpleField name={'person.registrationNr'} label={'Registrinumber*'} required max={"99999999"} type={"number"}/>
                                    <SimpleField name={'person.companyName'} label={'Ettevõtte nimi*'} required/>
                                </> :
                                <>
                                    <SimpleField name={'person.personCode'} label={'Isikukood*'} required/>
                                    <SimpleField name={'person.firstName'} label={'Nimi*'} required/>
                                    <SimpleField name={'person.lastName'} label={'Perenimi*'} required/>
                                </>
                            }
                            <FormHeader icon={address} text={'AADRESS'} />
                            <SimpleField name={'address.address'} label={'Aadress*'} required/>
                            <SimpleField name={'address.postalCode'} label={'Postiindeks*'} required max={"99999"} type={"number"}/>
                            <SimpleField name={'address.city'} label={'Linn/Vald*'} required/>
                            <SimpleField name={'address.county'} label={'Maakond*'} required/>
                            <FormHeader icon={contact} text={'SIDEVAHENDID'} />
                            <SimpleField name={'contacts.phoneNr'} label={`Telefon${(!values.contacts.email || values.contacts.email.length === 0) ? '*' : ''}`} type={'tel'} required={!values.contacts.email || values.contacts.email.length === 0} />
                            <SimpleField name={'contacts.email'} type={'email'} label={`E-post${(!values.contacts.phoneNr || values.contacts.phoneNr.length < 6) ? '*' : ''}`} required={!values.contacts.phoneNr || values.contacts.phoneNr.length < 6} />
                            <FormHeader icon={newUser} text={'ESINDAJA'} />
                            <SelectField name={'representative.representType'} label={values.person.personType === 'BUSINESS' ? 'Esindusõiguse alus*' : 'Esindusõiguse alus (valikuline)'} required={values.person.personType === 'BUSINESS'}
                                         options={[
                                             {code: 'Seadus', value: 'Seadus'},
                                             {code: 'Leping', value: 'Leping'},
                                             {code: 'Volitus', value: 'Volitus'}
                                         ]}/>
                            <SimpleField name={'representative.firstName'} label={values.person.personType === 'BUSINESS' ? 'Eesnimi*' : 'Eesnimi (valikuline)'} required={values.person.personType === 'BUSINESS'} />
                            <SimpleField name={'representative.lastName'} label={values.person.personType === 'BUSINESS' ? 'Perenimi*' : 'Perenimi (valikuline)'} required={values.person.personType === 'BUSINESS'} />
                            <SimpleField name={'representative.email'} type={'email'} label={values.person.personType === 'BUSINESS' ? 'E-post*' : 'E-post (valikuline)'} required={values.person.personType === 'BUSINESS'} />
                            <SimpleField name={'representative.phoneNr'}  label={values.person.personType === 'BUSINESS' ? 'Telefon*' : 'Telefon (valikuline)'} type={'tel'} required={values.person.personType === 'BUSINESS'} />
                            <span style={{marginTop: '33px'}}/>
                            <CheckBoxField name={'rememberMe'} type={'checkbox'} label={'Jäta minu andmed meelde'}/>
                            <button className={'btn forward-button'} type={'submit'} disabled={isSubmitting}>
                                EDASI
                            </button>
                            <button className={'btn back-button'} type={'button'} disabled={isSubmitting} onClick={() => push('/')}>
                                TAGASI
                            </button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}

export default connect(null, { push })(ReporterForm);
