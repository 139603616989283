import React from 'react';
import mail from "../asset/footericon-mail.svg";
import phone from "../asset/footericon-phone.svg";
import address from "../asset/footericon-pin.svg";
import SidebarMenu from "../components/menu/SidebarMenu";
import Cookies from "../components/cookies/Cookies";

const Layout = ({component, extraClassNames}) => {
    const Component = component;

    return (
        <>
            <SidebarMenu />
            <div id={'App'} className={`flex-container ${extraClassNames ? extraClassNames : ''}`}>
                <div className={'content'}>
                    <Component id={'page-wrap'}/>
                </div>
            </div>
           <Cookies/>
            <div className={'footer'}>
                <div className={'footer-content'}>
                    <div className={'footer-block'}>
                        <span className={'footer-contacts-header'}>KREDIIDIVALVE OÜ</span>
                        <div className={'footer-contacts'}>
                            <div className={'footer-contact'}>
                                <img className={'footer-icon'} alt={''} src={phone} style={{height: '20px'}}/>
                                <a className={'footer-text'} href={'tel:+372-655-0001'}>+372 655 0001</a>
                            </div>
                            <div className={'footer-contact'}>
                                <img className={'footer-icon'} alt={''} src={mail}/>
                                <a className={'footer-text'} href={'mailto:menetlus@krediidivalve.ee'}
                                   target={"_top"}>info@krediidivalve.ee</a>
                            </div>
                            <div className={'footer-contact'} style={{paddingLeft: '2px'}}>
                                <img className={'footer-icon'} alt={''} src={address} style={{height: '20px'}}/>
                                <a className={'footer-text'} href={'https://www.google.com/maps/place/Hariduse+13,+10119+Tallinn'}
                                   target={'_blank'} rel="noopener noreferrer">Hariduse 13-1a, Tallinn</a>
                            </div>
                        </div>
                    </div>
                    <div className={'footer-block'}>
                        <span className={'footer-block-header'} style={{marginBottom: '13px'}}>Tingimused:</span>
                        <a className={'footer-block-link'} href={'/menetluse-põhimõtted'} target={'blank'}>Menetluspõhimõtted</a>
                        <a className={'footer-block-link'} href={'/hinnastamise-põhimõtted'} target={'blank'}>Hinnastamise põhimõtted</a>
                        <a className={'footer-block-link'} href={'/tingimused'} target={'blank'}>Üld- ja maksetingimused</a>
                        <a className={'footer-block-link'} href={'/andmekaitsetingimused'} target={'blank'}>Andmekaitsetingimused</a>
                    </div>
                </div>
            </div>
        </>
    )
};

export default (component, extraClassNames) => <Layout component={component} extraClassNames={extraClassNames}/>
