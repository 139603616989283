import React from 'react';
import {connect} from "react-redux";
import {push} from "connected-react-router";
import './Proceeding.css'

const Proceeding = ({push}) => {
    return (
        <div className={'proceeding'}>
            <div className={'subpage-header'}>MENETLUSE PÕHIMÕTTED</div>
            <div className={'proceeding-text-main proceeding-text'}>
                Kohtuväline sissenõudeteenus
            </div>
            <div className={'proceeding-section'}>
                <div className={'proceeding-section-description'}>
                    Krediidivalve teostab alljärgnevale kirjeldusele vastava sissenõudmismenetluse. Konkreetsel juhul võib sissenõudmismenetlus erineda tulenevalt asjaolust, et võlgnik tasub võlanõude osaliselt, vaidlustab võlanõude, lepitakse kokku maksegraafikus, tagastatakse väljasaadetud kiri aadressandmete tõttu vms.<br/><br/>
                    Krediidivalve ei registreeri nõuet võlanõudena enne maksemeeldetuletuse edastamist kohustatud poolele. Maksemeeldetuletus postitatakse kohustatud poolele kliendi nimel ja palutakse tasuda esimesel võimalusel. Juhul, kui tasumine ei toimu 10 päeva jooksul registreerib Krediidivalve nõude võlanõudena, käsitleb kohustatud poolt võlgnikuna ja alustab sissenõudmismenetlust alljärgneva menetluskirjelduse kohaselt.
                </div>
                <div className={'proceeding-duration'}>päev</div>
            </div>
            <hr className={'proceeding-separator'}/>
            <div className={'proceeding-section'}>
                <div className={'proceeding-section-description'}>
                    Esimene võlanõue postitatakse võlgnikule võlanõude registreerimisele järgneval tööpäeval tähtajaga 10 päeva arvates võlanõude väljastamise kuupäevast.</div>
                <div className={'proceeding-duration'}>1-10 päeva</div>
            </div>
            <hr className={'proceeding-separator'}/>
            <div className={'proceeding-section'}>
                <div className={'proceeding-section-description'}>Sissenõudmine telefoni teel sooritatakse juhul, kui:<br/><br/>
                    •  võlgniku telefoninumber on teada;<br/>
                    •  võlgnevus on endiselt tasumata.<br/><br/>
                    Sissenõudmine telefoni teel ajastatakse esimese võlanõude kättesaamise ja teise võlanõude postitamise vahelisele ajale.
                </div>
            </div>
            <hr className={'proceeding-separator'}/>
            <div className={'proceeding-section'}>
                <div className={'proceeding-section-description'}>
                    Teine  võlanõue postitatakse 14. päeval pärast võlanõude registreerismist tähtajaga 10 päeva, kui:<br/><br/>
                    •  võlgnik ei ole võlanõuet tasunud hoolimata esimesest võlanõudest;<br/>
                    •  võlgnikuga ei ole sõlmitud maksekokkulepet ja sissenõudmist ei ole muul põhjusel peatatud.
                </div>
                <div className={'proceeding-duration'}>14-24 päeva</div>
            </div>
            <hr className={'proceeding-separator'}/>
            <div className={'proceeding-section'}>
                <div className={'proceeding-section-description'}>
                    Kolmas võlanõue on hoiatus võimaliku kohtumenetluse alustamisest, mis postitatakse võlgnikule e-postiga või vajadusel tähitult juhul, kui:<br/><br/>
                    •  võlgnik ei ole võlgnevust tasunud eelnevatest võlanõuetest hoolimata;<br/>
                    •  võlgnikuga ei ole sõlmitud maksekokkulepet<br/>
                    •  sissenõudmist ei ole muul põhjusel peatatud.<br/><br/>
                    Kolmas võlanõue hoiatab tasumata jätmise korral võlgniku nime avalikustamisest üleriigilises päevalehes nn. krooniliste võlgnike nimekirjas ja taustauuringuid teostavate äriühingute andmebaasides. Maksetähtaeg on koheselt.
                </div>
                <div className={'proceeding-duration'}>28 päeva</div>
            </div>
            <hr className={'proceeding-separator'}/>
            <div className={'proceeding-section'}>
                <div className={'proceeding-section-description'}>Maksekokkulepete jälgimine.<br/><br/>
                    Aadressipäringute tegemine ja nende alusel võlanõuete postitamine.
                </div>
                <div className={'proceeding-duration'}>29-59 päeva</div>
            </div>
            <hr className={'proceeding-separator'}/>
            <div className={'proceeding-section'}>
                <div className={'proceeding-section-description'}>Juhul, kui võlgnevus jääb nimetatud toimingute ajal tasumata, väljastab Krediidivalve kliendile nimetatud nõude ülesandes ettepaneku krediidikahjumi kirjendamiseks ja võlgnevuse sissenõudmise jätkamiseks järelsissenõudmise korras.</div>
                <div className={'proceeding-duration'}>~60 päeva</div>
            </div>
            <hr className={'proceeding-separator'}/>
            <div className={'proceeding-section'}>
                <div className={'proceeding-section-description'}>Järelsissenõudmine:<br/><br/>
                    •  kohtuväline sissenõudmine kirja ja telefoni teel;<br/>
                    •  kohtumenetlus (hagi- või pankrotimenetlus) kokkuleppel kliendiga.</div>
            </div>
            <hr className={'proceeding-separator'}/>
            <div className={'proceeding-section'}>
                <div className={'proceeding-section-description'}>Sissenõudmine lõpetatakse juhul, kui:<br/><br/>
                    •  võlgnevus on tasutud;<br/>
                    •  klient avaldab soovi sissenõudmise lõpetamiseks;<br/>
                    •  võlgnik tunnistatakse püsivalt maksejõuetuks;<br/>
                    •  sissenõudmise jätkamise kulud osutuvad võlanõudest suuremaks.
                </div>
            </div>
            <hr className={'proceeding-separator'}/>
            <button className={'btn forward-button subpage-bottom-button'} type={'button'} onClick={() => {
                push('/')
            }}>
                ALUSTAN MENETLUST
            </button>
        </div>
    )
};

export default connect(null, {push})(Proceeding);
