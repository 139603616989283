export const getJsonValue = (key) => {
  return JSON.parse(sessionStorage.getItem(key))
};

export const setJsonValue = (key, value) => {
    sessionStorage.setItem(key, JSON.stringify(value));
};

export const setValue = (key, value) => {
    sessionStorage.setItem(key, value);
};

export const getValue = (key) => {
    return sessionStorage.getItem(key);
};

export const getPermJsonValue = (key) => {
    return JSON.parse(localStorage.getItem(key))
};

export const setPermJsonValue = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
};

export const setPermValue = (key, value) => {
    localStorage.setItem(key, value);
};

export const getPermValue = (key) => {
    return localStorage.getItem(key);
};
