import React from 'react';
import {connect} from "react-redux";
import {push} from "connected-react-router";
import './Terms.css'

const Terms = ({push}) => {
    return (
        <div className={'terms'}>
        <div className={'subpage-header'}>ÜLD- JA MAKSETINGIMUSED</div>
        <div className={'terms-text'}>
            Käesolevad üldtingimused on kliendi ja Krediidivalve OÜ (edaspidi Krediidivalve) vahel sõlmitud lepingu
            (edaspidi leping) lahutamatuks osaks. Üldtingimusi kohaldatakse, kui poolte vahel ei ole kirjalikult
            kokku lepitud teisiti.
        </div>
        <div className={'terms-text'}>
            1. Lepingu objektiks on võlgade sissenõudmisteenuse osutamine, mis seisneb Krediidivalve poolt kliendile kolmandate isikute poolt tasumata sissenõutavaks muutunud nõuete (edaspidi võlanõue või võlanõuded) sissenõudmises ning sellega kaasnevas juriidilises asjaajamises ning läbirääkimiste pidamises kliendi võlgnikega (edaspidi võlgnik).
        </div>
        <div className={'terms-text'}>
            2. Pooled lähtuvad võlanõuete sissenõudmisel headest äri- ja sissenõudmistavadest. Pooled kohustuvad hoidma lepingu ja selle täitmisega seonduvaid andmeid ning lepingu täitmise raames teisele poolele üleantud või vormistatud dokumente konfidentsiaalsetena. Lepingu täitmise raames saadud teavet on Krediidivalvel õigus kasutada ainult Lepingus toodud konkreetsete võlanõuete lahendamisega seotud ülesannete täitmiseks, kui pooled ei ole kokku leppinud teisiti.
        </div>
        <div className={'terms-text'}>
            3. Klient kinnitab, et kõik Krediidivalvele üle antavad võlanõuded on põhjendatud, korrektselt vormistatud ning võlanõue on tekkinud õiguslikul alusel. Krediidivalve kinnitab, et teostab võlanõude sissenõudmiseks legaalseid toiminguid, ei kahjusta kliendi mainet ja tegutseb vastavalt headele sissenõudmistavadele.
        </div>
        <div className={'terms-text'}>
            4. Krediidivalve ei kanna varalist vastutust kliendi ees võlgniku poolt võlgade osalise tasumise või täieliku mittetasumise korral.
        </div>
        <div className={'terms-text'}>
            5. Stardiraha tasumise hetkest klient annab ja Krediidivalve võtab asja enda menetlusse ning Krediidivalve asub kliendi esindajana teostama juriidilisi toiminguid, mis on vajalikud saavutamaks võlanõuete tasumine võlgniku poolt. Alates nimetatud hetkest loetakse kõik kliendilt Krediidivalve menetlusse antud võlanõuete alusel laekuvad summad tasutuks Krediidivalve töö tulemusena ning selliste summade laekumisel kliendile on Krediidivalvel õigus menetlustasule vastavalt hinnastamise põhimõtetele.
        </div>
        <div className={'terms-text'}>
            6. Krediidivalve teostab kliendi võlanõuete sissenõudmist vastavalt menetluse põhimõtetele. Krediidivalvel on õigus muuta teenuste sisu ilma kliendiga eraldi kokkuleppe sõlmimiseta, kui nimetatud muudatused on vajalikud teenuste seaduslikkuse ja ajakohastamise seisukohalt. Kui klient põhjendatult ei nõustu tehtud muudatustega, on kliendil õigus leping üles öelda. Krediidivalvel on õigus teenustasule enne lepingu ülesütlemist teostatud toimingute tulemusel laekunud summadelt.
        </div>
        <div className={'terms-text'}>
            7. Klient annab võlanõuete sissenõudmise seisukohalt olulise informatsiooni ja dokumendid ning teatab Krediidivalvele viivitamatult temale teadaolevatest maksetest, hüvitistest, võlanõuete vaidlustamistest või muudest võlanõuete sissenõudmist puudutavatest asjaoludest. Klient vastutab tema poolt esitatud dokumentide, informatsiooni ja asjaolude seaduslikkuse ja tõepärasuse eest.
        </div>
        <div className={'terms-text'}>
            8. Krediidivalve arvestab Võlgnikult sisse nõutud summad esmajärjekorras sissenõudmiskulude, seejärel viiviste ning viimasena põhiosa katteks. Sissenõudmiskulud loetakse Krediidivalve tasu osaks ja need ei kuulu kliendile üleandmisele.
        </div>
        <div className={'terms-text'}>
            9. Krediidivalve hoiab võlgnikelt sissenõutud summad eraldi kliendivarade arvelduskontol ja sooritab ülekanded nimetatult arvelt kliendi poolt lepingus näidatud arveldusarvele vastavalt infovahetuses esitatud tingimustele.
        </div>
        <div className={'terms-text'}>
            10. Krediidivalve ei vastuta kahjude eest, mis on tekkinud temast sõltumatutel asjaoludel, valuutakursside muutumisest või vääramatust jõust või mille ärahoidmine eeldaks majanduslikke või muid meetmeid, mis võrreldes tekkida võiva kahjuga on tunduvalt kulukamad. Krediidivalve ei vastuta kliendi ja võlgniku vahel tekkida võivate kahjude eest. Krediidivalve vastutab tööülesannete täitmisel kliendile süüliselt tekitatud otsese varalise kahju eest kuni tööülesannete täitmise eest makstud teenustasu summa ulatuses. Krediidivalve ei vastuta saamata jäänud tulu ja mittevaralise kahju eest.
        </div>
        <div className={'terms-text'}>
            11. Leping on sõlmitud tähtajatult. Poolel on õigus leping ühepoolselt lõpetada, teatades sellest teisele poolele kirjalikult vähemalt 3 (kolm) kuud ette. Krediidivalve poolt lepingu mittenõuetekohasel täitmisel on kliendil õigus leping ühepoolselt lõpetada, teatades sellest kirjalikult 2 (kaks) kuud ette. Leping lõpeb pärast kõikide lepingust tulenevate kohustuste täitmist. Krediidivalve jätkab lepingu lõpetamisest teatamise ja lepingu lõppemise tähtpäeva vahele jääva perioodi jooksul juba menetluses olevate võlanõuete sissenõudmist ja klient on kohustatud nimetatud võlanõuete osalise või täieliku tasumise korral maksma Krediidivalvele menetlustasu vastavalt hinnastamise põhimõtetele. Krediidivalvel on õigus teenustasule ka summadelt, mille võlgnik tasub pärast lepingu lõppemist.
        </div>
        <div className={'terms-text'}>
            12. Lepingule kohaldatakse Eesti Vabariigi seadusandlust. Lepingust tuleneda võivad erimeelsused püütakse lahendada läbirääkimiste teel, kokkuleppe mittesaavutamisel vaatab vaidluse läbi Harju Maakohus. Kohtualluvuse kokkulepe kehtib juhul, kui klient tegutseb võlanõuete sissenõudmiseks esitamisel oma majandus- või kutsetegevuse raames, kliendi elu- või asukoht ei ole Eestis või juhul, kui füüsilisest isikust klient asub pärast lepingu sõlmimist elama välisriiki või kui viib sinna üle oma tegevuskoha või kui tema tegevus-, elu- või asukoht ei ole hagi esitamise ajal teada.
        </div>
        <div className={'terms-text'}>
            13. Ostuprotsess ja maksetingimused:
            <br/><br/>
            Sissenõudmismenetluse alustamiseks tuleb täita nõutud andmeväljad. Andmeväljad on jagatud kolme rühma:
            <br/><br/>
            • võlgniku andmed: isik, aadress, sidevahendid;<br/>
            • avaldaja andmed: isik, aadress, sidevahendid;<br/>
            • nõude andmed: arve/lepingu andmed; nõudega seotud dokumendid.
            <br/><br/>
            Järgmise sammuna kuvatakse menetlustaotlus. Menetlustaotlus koosneb avaldaja poolt sisestatud andmetest ning Krediidivalve tingimustest.
            <br/><br/>
            Seejärel kuvatakse ekraanile tasu suurus, mille saab tasuda turvaliselt läbi järgnevate makseviiside:
            <br/><br/>
            • Eesti pangalingid: Swedbank, SEB, Luminor, LHV, Coop Pank, Pocopay;<br/>
            • Visa/Mastercard kaardimaksed.
            <br/><br/>
            NB! Pangalingiga tasumisel vajutada kindlasti pangalehel nupule „Tagasi kaupmehe juurde“.
            <br/><br/>
            Makseid vahendab Maksekeskus AS. Tasumine toimub väljaspool Krediidivalve OÜ veebilehekülge turvalises keskkonnas – pangalingiga tasumisel vastava panga turvalises keskkonnas ja krediitkaardiga tasumisel Maksekeskus AS turvalises keskkonnas. Krediidivalve OÜ’l puudub ligipääs kliendi panga ja krediitkaardi andmetele. Leping jõustub alates tasumisele kuuluva summa laekumisest Krediidivalve OÜ arvelduskontole.
            <br/><br/>
            Krediidivalve OÜ on isikuandmete vastutav töötleja ning edastab maksete teostamiseks vajalikud isikuandmed volitatud töötleja Maksekeskus AS-le.
            <br/><br/>
            Võlamenetluse algatamine
            <br/><br/>
            Pärast edukat stardiraha tasumist edastame Teie poolt sisestatud e-maili aadressile arve ja menetlustaotluse ning algatame võlamenetluse. (Vt: üldtingimuste punkt nr. 5 ning <a href={"/menetluse-põhimõtted"} style={{color: '#393d3f', textDecoration: 'underline', cursor: 'pointer'}} target={'blank'}>menetluspõhimõtted</a>)
            <br/><br/>
            Taganemisõigus
            <br/><br/>
            Vastavalt võlaõigusseaduse §53 lg 4-le ei kohaldata taganemisõigust lepingule, mille esemeks on teenuse osutamine või muu kestva soorituse tegemine, kui ettevõtjapoolsed lepingust tulenevad kohustused on täielikult täidetud ning teenuse osutamine või muu soorituse tegemine on alanud tarbija sõnaselgel eelneval nõusolekul ning kinnitusel, et ta kaotab ettevõtjapoolsel lepingu täitmisel oma taganemisõiguse.
            <br/><br/>
            Üldtingimused kehtivad alates: 04.08.2020.
        </div>
        <button className={'btn forward-button subpage-bottom-button'} type={'button'} onClick={() => {push('/')}}>
            TAGASI
        </button>
        </div>
    )
};

export default connect(null, {push})(Terms);
