import React from 'react';
import {connect} from "react-redux";
import {push} from "connected-react-router";
import './Gdpr.css'

const Gdpr = ({push}) => {
    return (
        <div className={'gdpr'}>
            <div className={'subpage-header'}>ANDMEKAITSETINGIMUSED</div>
            <div className={'gdpr-text'}>
                Isikuandmete töötlemise teave on koostatud vastavalt EL-i
                isikuandmete kaitse üldmääruse 2016/679 artiklile 12.
            </div>
            <div className={'gdpr-text'}>
                1. Käesolevad andmekaitsetingimused ei hõlma juriidiliste isikute andmete hoidmist ja isikuandmete töötlemist võõrastel võrgulehtedel, millele Krediidivalve võrgulehel viidatakse (välislingid).
            </div>
            <div className={'gdpr-text'}>
                2. Krediidivalve töötleb Teie isikuandmeid kui Te:
            </div>
            <div className={'gdpr-text'}>
                • külastate Krediidivalve veebilehte;<br/>
                • kandideerite meile tööle või olete meil töötanud;<br/>
                • esitate meile taotluse algatada sissenõudmismenetlus, teabenõude taotluse, selgitustaotluse või märgukirja;<br/>
                • võtate ühendust kasutajatoega (telefonikõned salvestatakse);<br/>
                • külastate Krediidivalve kontorit;<br/>
                • Teie andmed on kantud Krediidivalve kliendihaldustarkvarasse (edaspidi CRM) (oleme andmekogu vastutav töötleja).
            </div>
            <div className={'gdpr-text'}>
                2.1.1 Krediidiivalve veebilehe külastamine
            </div>
            <div className={'gdpr-text'}>
                Kui Te külastate Krediidivalve veebilehte, piirduvad teie kohta kogutavad ja säilitatavad isikuandmed järgnevaga:<br/><br/>
                • kasutatava arvuti või arvutivõrgu internetiaadress (IP-aadress);<br/>
                • arvuti veebilehitseja ja operatsioonisüsteemi andmed;<br/>
                • külastamise aeg (kellaaeg, kuupäev).<br/><br/>
                Krediidvalve ei seo kogutud IP –aadresse ühegi konkreetse külastajaga. Statistikat kogutakse selle kohta,
                millist veebilehe osa külastate ja kui kaua seal peatute. Neid andmeid kasutatakse külastusstatistika koostamiseks,
                et selle põhjal veebilehte arendada ja muuta see külastajasõbralikumaks. Andmeid säilitatakse 12 kuud.
            </div>
            <div className={'gdpr-text'}>
                2.1.2 Maksed veebilehel
            </div>
            <div className={'gdpr-text'}>
                Krediidivalve OÜ edastab maksete teostamiseks vajalikud isikuandmed volitatud töötleja Maksekeskus AS-le.
            </div>
            <div className={'gdpr-text'}>
                2.2.1 Krediidivalvesse tööle või praktikale kandideerimine
            </div>
            <div className={'gdpr-text'}>
                Tööle või praktikale kandideerides kasutame teie poolt esitatud andmeid üksnes järgnevatel eesmärkidel:<br/><br/>
                • teie tööle kandideerimise avalduse hindamiseks;<br/>
                • teie kvalifikatsiooni hindamiseks ja töölevõtmisega seotud otsuste tegemiseks;<br/>
                • teiega suhtlemiseks, nt teabe andmiseks töövestluse võimalike kuupäevade kohta;<br/>
                • baasteabematerjali koostamiseks, mida kasutatakse juhul, kui teid tööle võetakse.<br/><br/>
                Kandidaadi isikuandmeid töötlevad värbamisprotsessis osalevad töötajad.
                Teistel töötajatel juurdepääs tööle kandideerija isikuandmetele puudub.
                Kandidaatide isikuandmed kogunevad kandideerimiskuulutuses nõutavate dokumentide esitamisel, sealjuures:<br/><br/>
                • lähtutakse kandidaadi enda avaldatud teabest ning avalikest allikatest (sh suhtlusvõrgustikud, blogid, avalikud registriandmed k.a karistusregister, äriregister, kohtulahendid jne);<br/>
                • on kandidaadil õigus teada, milliseid andmeid on tema kohta kogutud;<br/>
                • on kandidaadil õigus kogutud andmetega tutvuda ja anda selgitusi ning esitada vastuväiteid
                või nõuda andmete parandamist vastavalt tegelikkusele;<br/>
                • eeldame, et kandideerimisdokumentides esitatud soovitajatele on kandideerija andnud nõusoleku enda kohta käivatele
                küsimustele vastamiseks, samuti on soovitajad nõustunud sellega, et Krediiidvalve võib nende poole teabe saamiseks pöörduda.<br/>
                • ei avaldata teie andmeid teistele kandidaatidele, samuti ei avaldata teile teiste kandidaatide andmeid.<br/><br/>
                Kandidaatide andmed on juurdepääsupiiranguga teave, millele kolmandad isikud (sh pädevad asutused) saavad juurdepääsu ainult seaduses sätestatud juhtudel.<br/>
                Kirjavahetust töösuhete, personali jmt küsimustes, säilitame 5 aastat. Praktika-, käsundus- ja töölepinguid säilitame 10
                aastat alates lepingu lõpetamisest. Tähtaja ületanud dokumendid kuuluvad hävitamisele.
            </div>
            <div className={'gdpr-text'}>
                2.3.1 Menetluse algatamise dokumendi, teabenõude, selgitustaotluse ja märgukirja esitamine
            </div>
            <div className={'gdpr-text'}>
                Krediidivalve kasutab teie isikuandmeid menetluste algatamiseks ning teile vastamiseks. Teie pöördumised registreeritakse
                CRMis ning edastatakse vastamiseks asjassepuutuvatele töötajatele. Kui peame teile vastamiseks kelleltki teiselt
                järelepärimisi tegema, avaldame teie isikuandmeid üksnes hädavajalikus mahus. Kui olete saatnud meile pöördumise,
                millele vastamine on teise asutuse pädevuses, edastame selle asjaomasele asutusele. Pöördumise edastamisest teavitame ka teid.
                Kui keegi soovib teie kirjavahetusega tutvuda, siis juurdepääsupiirang tähendab, et teabenõude saamisel vaatame üle, kas
                küsitud dokumenti saab välja anda ning kas seda saab teha osaliselt või täielikult.<br/><br/>

                Sõltumata juurdepääsupiirangust väljastame dokumendi asutusele või isikule, kellel on otsene seadusest tulenev õigus seda küsida (nt uurimisasutus, kohtuväline menetleja või kohus).<br/>
                Teiega peetud kirjavahetust võime kasutada ka asutusesiseselt oma töö kvaliteedi hindamiseks. Kirjavahetuse statistikat ja kokkuvõtteid avaldame umbisikuliselt, ilma nimedeta.<br/>
                Eraisikutega peetud kirjavahetust (teabenõuded/märgukirjad jne) säilitame viis aastat. Selle tähtaja ületanud dokumendid hävitatakse ja andmed kustutatakse.
            </div>
            <div className={'gdpr-text'}>
                2.4.1 Kasutajatoe poole pöördumine
            </div>
            <div className={'gdpr-text'}>
                Krediidivalve kasutajatoele (+372 655 0001 või +372 655 0002) saabunud kõned salvestatakse ja sellest teavitatakse helistajat
                kõne alguses. Salvestusi kasutatakse parema teeninduse tagamiseks ning neid säilitatakse isikustamata kujul vähemalt 6 kuud.
                Salvestuste hävitamine toimub salvestussüsteemi poolt automaatselt kindla aja möödudes.<br/><br/>

                Kui Te ei soovi salvestamist, on Teil võimalik kõnest loobuda ning valida mõni muu teabe saamise viis
                (näiteks saates kiri e-posti või tavapostiga).
            </div>
            <div className={'gdpr-text'}>
                2.5.1 Krediidvalve kontori külastamine
            </div>
            <div className={'gdpr-text'}>
                Krediidivalve kontoris on Krediidivalve töötajal õigus paluda teilt isikut tõendavat dokumenti, et kontrollida isikusamasust. Krediidivalve hoones ja selle ümbruses on ööpäevaringne videovalve. Isikute liikumine hoones ja territooriumil (parklad, hoonega piirnevad alad) salvestatakse turvakaalutlustel. Videosalvestisi ja uksekaardi logisid haldab Krediidivalve. Salvestisi säilitatakse 30 kalendripäeva ja uksekaardilogisid 1 aasta.
            </div>
            <div className={'gdpr-text'}>
                2.6.1 Kui teie isikuandmed on kantud CRMi.
            </div>
            <div className={'gdpr-text'}>
                Eesmärk<br/><br/>
                CRM on veebipõhine kliendihaldustarkvara, mis aitab meil kliendihaldust korraldada. Tarkvara asub meie teenusepakkuja serveris ja seda kasutavad üksnes meie töötajad.
                <br/><br/>
                Õiguslik alus<br/><br/>
                Võlamenetluse algatamine eeldab lepingu sõlmimist. Kliendi ja Krediidivalve vahelise lepingu sõlmimine toimub paberkandjal või digitaalselt.
                <br/><br/>
                Säilitustähtaeg<br/><br/>
                Kliendil on CRMi kasutamisel õigus lisada menetluse läbiviimiseks vajalikus mahus andmeid. Säilitame andmeid CRMi arhiivis 7 aastat alates võlamenetluse lõpetamisest.
                <br/><br/>
                Juurdepääs CRM-ile<br/><br/>
                Tulenevalt Krediidivalve ja kliendi vahel sõlmitava lepingu tingimustest hoiame CRMis sisalduvat konfidentsiaalset teavet, mis ei ole avalikult kättesaadav.
                Konfidentsiaalsuskohustus kehtib tähtajatult kõigi isikute ja asutuste suhtes (sh riigiasutused).
                Krediidivalve on vastutavaks töötlejaks andmekogu majutamisel, hooldamisel ja arendustööde tegemisel.
                Sellest tulenevalt pääsevad andmetele juurde Krediidivalve töötajad, kellel on selleks tööülesannete täitmisest tulenevalt otsene vajadus
                (sh andmebaasi tehniline kasutajatugi).
            </div>
            <div className={'gdpr-text'}>
                3. Enda kohta käivate andmetega tutvumine
            </div>
            <div className={'gdpr-text'}>
                Teil on õigus:<br/><br/>
                • tutvuda andmetega, mida Krediidivalve on Teie kohta kogunud;<br/>
                • taotleda ebaõigete isikuandmete parandamist või täiendamist, kui need on valed või ebapiisavad;<br/>
                • nõuda isikuandmete kustutamist, mille kasutamiseks puudub Krediidivalvel seaduslik alus;<br/>
                • taotleda isikuandmete töötlemise piiramist (nt ajaks kui Teie isikuandmete õigsust kontrollitakse);<br/>
                • esitada vastuväiteid Teid puudutavate isikuandmete töötlemise suhtes.
                Selleks saate edastada meile pöördumise, kasutades pöördumiseks e-posti aadressi andmekaitse@krediidivalve.ee<br/><br/>

                Krediidivalve hallatavates andmekogus olevate teie kohta käivate isikuandmetega tutvumiseks palume teil pöörduda meie poole
                andmekaitse@krediidivalve.ee. Vastame teie esitatud taotlusele esimesel võimalusel, kuid mitte hiljem kui ühe kuu jooksul
                pärast pöördumise saamist. Kui ühe kuu jooksul ei ole võimalik andmeid väljastada, teavitame teid sellest ja pikendame
                vastamise tähtaega. Teie kohta kogutud andmed väljastame vastavalt teie soovile kas paberkandjal või elektrooniliselt.
                Kui esineb põhjendatud kahtlus taotluse esitanud isiku identiteedi suhtes, võime nõuda taotluse esitaja isiku tuvastamiseks
                täiendava teabe esitamist.
                <br/><br/>
                Juhul, kui taotlus on põhjendamatu või ülemäärane (eelkõige korduva iseloomu tõttu), on Krediidivalvel õigus küsida taotluse täitmise eest mõistlikku tasu või keelduda andmete väljastamisest.
            </div>
            <div className={'gdpr-text'}>
                3.1. Keeldume teie tutvumissoovi täitmast, kui see võib:
            </div>
            <div className={'gdpr-text'}>
                • kahjustada teise isiku õigusi ja vabadusi;<br/>
                • takistada kuriteo tõkestamist või kurjategija tabamist;<br/>
                • raskendada tõe väljaselgitamist kriminaalmenetluses.<br/>
                Teil on õigus esitada meie otsuste peale vaie Andmekaitse Inspektsioonile või pöörduda kohtusse.
            </div>
            <div className={'gdpr-text'}>
                4. Isikuandmete töötlemisega seotud rikkumine
            </div>
            <div className={'gdpr-text'}>
                Kui Krediidivalves toimub isikuandmete töötlemise rikkumisega seotud intsident, siis registreerime juhtunu ja vormistame selle kohta nõutud dokumendid. Kui juhtunu kujutab endast tõenäolist ohtu teie õigustele ja vabadustele, siis teavitame sellest Andmekaitse Inspektsiooni. Rikkumise kohta, mis võib tõenäoliselt kujutada ohtu teie õigustele ja vabadustele, saate täpsemalt lugeda Andmekaitse Inspektsiooni isikuandmete töötleja üldjuhendist. Kui rikkumise tulemusena tekib teie õigustele ja vabadustele tõenäoliselt suur oht, siis teavitame juhtunust ka teid, et võimaldada teil olukorra leevendamiseks kasutusele võtta vajalikke ettevaatusabinõusid. Kui rikkumine on toimunud või avastamise hetkel veel toimumas, siis võtame kasutusele kõik vajalikud meetmed rikkumise lõpetamiseks ning tagajärgede leevendamiseks.
            </div>
            <div className={'gdpr-text'}>
                5. Tunne oma klienti ja käibemaksu informatsioon.
            </div>
            <div className={'gdpr-text'}>
                Rahapesu ja terrorismi rahastamise tõkestamist reguleerivate õigusaktide kohaselt peab Krediidivalve tuvastama kliendi, kliendi nimel tegutsevad isikud ja kliendi lõplikud kasusaajad. Teatud juhtudel on Krediidivalvel kohustus teha kindlaks kliendi varade päritolu. Krediidivalvel võib tekkida kohustus keelduda tööülesande täitmisest või peatada see ning teavitada võimuorganeid, kui klient ei esita nõutud informatsiooni, kui tehing on varasemalt teadaolevate tehingutega võrreldes ebatavaline või kui kahtlustatakse varade kasutamist terrorismi rahastamiseks või rahapesuks. Teatud juhtudel võib Krediidivalvel olla kohustus esitada maksu- ja tolliasutustele informatsiooni kliendi käibemaksukohustuslase numbri ja kliendi poolt ostetud teenuste maksumuse kohta. Lisaks võivad ametivõimud ja pankrotihaldurid nõuda teatud juhtudel ka arvete ja nende lisade esitamist. Klient saab aru ja nõustub, et need meetmed, mis on Krediidivalvele täitmiseks kohustuslikud, ei kujuta endast konfidentsiaalsuse hoidmise kohustuse rikkumist.
            </div>
            <div className={'gdpr-text'}>
                6. Kontaktandmed
            </div>
            <div className={'gdpr-text'}>
                Kõigis küsimustes, mis on seotud teie isikuandmete töötlemisega Krediidivalves, võite pöörduda meie asutuse andmekaitsespetsialisti poole e-posti aadressil andmekaitse@krediidivalve.ee
            </div>
            <button className={'btn  forward-button subpage-bottom-button'} type={'button'} onClick={() => {
                push('/')
            }}>
                TAGASI
            </button>
        </div>
    )
};

export default connect(null, {push})(Gdpr);
