import React from 'react';
import './Success.css';
import {connect} from "react-redux";
import {push} from "connected-react-router";

const Success = ({push}) => {
    return (
        <div>
            <div className={'subpage-header'}>AVALDUS EDASTATUD</div>
            <div className={'success-subheader'}>Täname, et tegite esimese sammu.</div>
            <div className={'success-text'}>Leiame, et kommunikatsioon on alus edukate suhete loomisel ja hoidmisel. Krediidivalve esindaja võtab Teiega kontakti esimesel võimalusel. Kiireloomuliste lisaküsimuste korral võtke meiega ühendust <a style={{color: '#393d3f'}} href={'tel:+372-655-0001'}>+372 655 0001.</a></div>
            <button className={'btn forward-button subpage-bottom-button'} type={'button'} onClick={() => {
                push('/')
            }}>
                AVALEHELE
            </button>
        </div>
    )
};

export default connect(null, {push})(Success);
