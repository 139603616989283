import React, {useEffect} from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import App from './components/dashboard/App';
import ReporterForm from "./components/business-form/ReporterForm";
import {default as defaultLayout} from "./layouts/default.layout";
import ClaimForm from "./components/business-form/ClaimForm";
import Contacts from "./components/contact/Contacts";
import Terms from "./components/terms/Terms";
import Debtor from "./components/debtor/Debtor";
import Application from "./components/application/Application";
import Gdpr from "./components/gdpr/Gdpr";
import Pricing from "./components/pricing/Pricing";
import Proceeding from "./components/proceeding/Proceeding";
import Checkout from "./components/checkout/Checkout";
import Creditor from "./components/creditor/Creditor";
import Success from "./components/success/Success";
import ApplicationSuccess from "./components/successApplication/ApplicationSuccess";
import Cookies from "./components/cookies/Cookies";

export const Routes = () => {
  return (
    <React.Fragment>
      <ScrollToTop/>
      <Switch>
        <Route exact path={'/'} render={() => defaultLayout(App, 'background-image')} />
        <Route path={'/menetlustaotlus/võlausaldaja'} render={() => defaultLayout(ReporterForm)} />
        <Route path={'/menetlustaotlus/nõuded'} render={() => defaultLayout(ClaimForm)} />
        <Route path={'/menetlustaotlus'} render={() => defaultLayout(Application)} />
        <Route path={'/kontakt'} render={() => defaultLayout(Contacts)} />
        <Route path={'/astu-esimene-samm'} render={() => defaultLayout(Debtor)} />
        <Route path={'/tingimused'} render={() => defaultLayout(Terms)} />
        <Route path={'/küpsised'} render={() => defaultLayout(Cookies)} />
        <Route path={'/andmekaitsetingimused'} render={() => defaultLayout(Gdpr)} />
        <Route path={'/hinnastamise-põhimõtted'} render={() => defaultLayout(Pricing)} />
        <Route path={'/menetluse-põhimõtted'} render={() => defaultLayout(Proceeding)} />
        <Route path={'/maksmine'} render={() => defaultLayout(Checkout)} />
        <Route path={'/võlausaldajale'} render={() => defaultLayout(Creditor)} />
        <Route path={'/avaldus-edastatud'} render={() => defaultLayout(ApplicationSuccess)} />
        <Route path={'/esimene-samm-tehtud'} render={() => defaultLayout(Success)} />
        <Route path={'*'} render={() => defaultLayout(App)}/>
      </Switch>
    </React.Fragment>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])

  return null;
};
