import React from 'react';
import './Application.css';
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {ProgressBar} from "../progress-bar/ProgressBar";
import mkl from "../../asset/icon-menetlustaotlus.svg";
import check from "../../asset/icon check.svg";
import {FormHeader} from "../business-form/FormHeader";
import {CheckBoxField} from "../form/simple-field";
import {Form, Formik} from "formik";
import {getJsonValue, getPermJsonValue, setJsonValue, setValue} from "../../store/session-storage-util";
import {format} from 'date-fns'

const Application = ({push}) => {
    const debtor = getJsonValue('debtorForm') || {person: {}, address: {}, contacts: {}};
    const reporter = getPermJsonValue('reporterForm') || getJsonValue('reporterForm') || {person: {}, address: {}, contacts: {}};
    let claims = getJsonValue('claimForm') || {claims: []};
    claims.files = getJsonValue('storedFiles');

    const totalSum = () => {
        let sum = claims.claims.map(c => Number(c.claimAmount) || 0).reduce((c1, c2) => c1 + c2, 0);
        let reductions = claims.claims.map(c => Number(c.claimPaidAmount) || 0).reduce((c1, c2) => c1 + c2, 0);
        return sum - reductions;
    };

    return (
        <div>
            <ProgressBar index={3}/>
            <Formik
                initialValues={{
                    isAcceptHonest: true,
                    isAcceptGdpr: false,
                    isAcceptPricing: false
                }}
                onSubmit={(values) => {
                    setJsonValue('extraParams', values);
                    push('/maksmine')
                }}
            >
                {({
                      isSubmitting
                  }) => (
                    <Form>
                        <FormHeader icon={mkl} text={'MENETLUSTAOTLUS'}/>
                        <div className={'application-header-row'} style={{marginTop: '42'}}>
                            <div>
                                <img alt={''} src={check}/>
                                <span className={'application-header-text'}>Võlausaldaja andmed</span>
                            </div>
                            <span className={'application-edit'} onClick={() => {
                                setValue('editingReporter', true);
                                push('/menetlustaotlus/võlausaldaja');
                            }}>muuda</span>
                        </div>
                        <hr style={{width: '100%', marginTop: '0px'}}/>
                        {reporter.person.personType === 'BUSINESS' &&
                            <>
                                <div className={'application-data-row'}>
                                    <div>Ettevõtte nimi:</div>
                                    <div className={'application-row-value'}>{reporter.person.companyName}</div>
                                </div>
                                <div className={'application-data-row'}>
                                    <div>Registrinumber:</div>
                                    <div className={'application-row-value'}>{reporter.person.registrationNr}</div>
                                </div>
                            </>
                        }
                        {reporter.person.personType === 'PRIVATE' &&
                            <>
                                <div className={'application-data-row'}>
                                    <div>Eesnimi:</div>
                                    <div className={'application-row-value'}>{reporter.person.firstName}</div>
                                </div>
                                <div className={'application-data-row'}>
                                    <div>Perenimi:</div>
                                    <div className={'application-row-value'}>{reporter.person.lastName}</div>
                                </div>
                                <div className={'application-data-row'}>
                                    <div>Isikukood:</div>
                                    <div className={'application-row-value'}>{reporter.person.personCode}</div>
                                </div>
                            </>
                        }
                        <div className={'application-data-row'}>
                            <div>Aadress:</div>
                            <div className={'application-row-value'}>{reporter.address.address}</div>
                        </div>
                        <div className={'application-data-row'}>
                            <div>E-post:</div>
                            <div className={'application-row-value'}>{reporter.contacts.email}</div>
                        </div>
                        <div className={'application-data-row'}>
                            <div>Telefon:</div>
                            <div className={'application-row-value'}>{reporter.contacts.phoneNr}</div>
                        </div>
                        <div className={'application-data-row'}>
                            <div>Esindusõiguse alus:</div>
                            <div className={'application-row-value'}>{reporter.representative.representType}</div>
                        </div>
                        <div className={'application-data-row'}>
                            <div>Esindaja eesnimi:</div>
                            <div className={'application-row-value'}>{reporter.representative.firstName}</div>
                        </div>
                        <div className={'application-data-row'}>
                            <div>Esindaja perenimi:</div>
                            <div className={'application-row-value'}>{reporter.representative.lastName}</div>
                        </div>
                        <div className={'application-data-row'}>
                            <div>Esindaja email:</div>
                            <div className={'application-row-value'}>{reporter.representative.email}</div>
                        </div>
                        <div className={'application-data-row'}>
                            <div>Esindaja telefon:</div>
                            <div className={'application-row-value'}>{reporter.representative.phoneNr}</div>
                        </div>
                        <div className={'application-header-row'}>
                            <div>
                                <img alt={''} src={check}/>
                                <span className={'application-header-text'}>Võlgniku andmed</span>
                            </div>
                            <span className={'application-edit'} onClick={() => {
                                setValue('editingDebtor', true);
                                push('/');
                            }}>muuda</span>
                        </div>
                        <hr style={{width: '100%', marginTop: '0px'}}/>
                        {debtor.person.personType === 'BUSINESS' &&
                        <>
                            <div className={'application-data-row'}>
                                <div>Ettevõtte nimi:</div>
                                <div className={'application-row-value'}>{debtor.person.companyName}</div>
                            </div>
                            <div className={'application-data-row'}>
                                <div>Registrinumber:</div>
                                <div className={'application-row-value'}>{debtor.person.registrationNr}</div>
                            </div>
                        </>
                        }
                        {debtor.person.personType === 'PRIVATE' &&
                            <>
                                <div className={'application-data-row'}>
                                    <div>Eesnimi:</div>
                                    <div className={'application-row-value'}>{debtor.person.firstName}</div>
                                </div>
                                <div className={'application-data-row'}>
                                    <div>Perenimi:</div>
                                    <div className={'application-row-value'}>{debtor.person.lastName}</div>
                                </div>
                                <div className={'application-data-row'}>
                                    <div>Isikukood:</div>
                                    <div className={'application-row-value'}>{debtor.person.personCode}</div>
                                </div>
                            </>
                        }
                        <div className={'application-data-row'}>
                            <div>Aadress:</div>
                            <div className={'application-row-value'}>{debtor.address && debtor.address.address}</div>
                        </div>
                        <div className={'application-data-row'}>
                            <div>E-post:</div>
                            <div className={'application-row-value'}>{debtor.contacts && debtor.contacts.email}</div>
                        </div>
                        <div className={'application-data-row'}>
                            <div>Telefon:</div>
                            <div className={'application-row-value'}>{debtor.contacts && debtor.contacts.phoneNr}</div>
                        </div>
                        <div className={'application-header-row'}>
                            <div>
                                <img alt={''} src={check}/>
                                <span className={'application-header-text'}>Nõuded</span>
                            </div>
                            <span className={'application-edit'} onClick={() => {
                                setValue('editingClaim', true);
                                push('/menetlustaotlus/nõuded');
                            }}>muuda</span>
                        </div>
                        <hr style={{width: '100%', marginTop: '0px'}}/>
                        {claims && claims.claims.map((claim) =>
                            <>
                                <div className={'application-data-row'}>
                                    <div>Arve/lepingu number:</div>
                                    <div className={'application-row-value'}>{claim.contractNr}</div>
                                </div>
                                <div className={'application-data-row'}>
                                    <div>Esitamise kuupäev:</div>
                                    <div className={'application-row-value'}>{format(new Date(claim.contractDate), 'dd.MM.yyyy')}</div>
                                </div>
                                <div className={'application-data-row'}>
                                    <div>Tasumise tähtaeg:</div>
                                    <div className={'application-row-value'}>{format(new Date(claim.dueDate), 'dd.MM.yyyy')}</div>
                                </div>
                                <div className={'application-data-row'}>
                                    <div>Arve põhisumma (€):</div>
                                    <div className={'application-row-value'}>{claim.claimAmount}</div>
                                </div>
                                <div className={'application-data-row'}>
                                    <div>Tänaseks tasutud (€):</div>
                                    <div className={'application-row-value'}>{claim.claimPaidAmount}</div>
                                </div>
                                <div className={'application-data-row'}>
                                    <div>Arvel on viivis:</div>
                                    <div className={'application-row-value'}>{claim.overdue ? "Jah" : "Ei"}</div>
                                </div>
                                <hr style={{width: '100%', marginTop: '0px'}}/>
                            </>
                        )}
                        <div className={'application-data-row'}>
                            <div>Põhinõude summa kokku:</div>
                            <div className={'application-row-value'}>{totalSum()} €</div>
                        </div>
                            {claims && claims.files && claims.files.map((file, i) =>
                                <div className={'application-data-row'}>
                                    <div>{i === 0 ? 'Nõude dokumendid:' : ''}</div>
                                    <div className={'application-row-value'}>{file.fileName}</div>
                                </div>
                            )}
                        <CheckBoxField name={'startProcessing'} type={'checkbox'}
                                       label={'Võlgniku vastuväite korral palun algatada kohtumenetlus'}/>
                        <hr style={{width: '100%', marginTop: '50px'}}/>
                        <CheckBoxField name={'isAcceptHonest'} type={'checkbox'} required readOnly
                                       label={'Kinnitan, et olen esitanud andmed ausalt oma parimate teadmiste kohaselt ja olen teadlik, et tahtlik valeandmete esitamine võib kaasa tuua vastutuse*'}/>
                        <CheckBoxField name={'isAcceptGdpr'} type={'checkbox'} required
                                       label={<span>Olen lugenud ja nõustun andmetöötlusega vastavalt Krediidivalve OÜ <a href={'/andmekaitsetingimused'} target={'blank'} style={{color: '#492392'}}>andmetöötluse korrale.</a>*</span>}/>
                        <CheckBoxField name={'isAcceptPricing'} type={'checkbox'} required
                                       label={<span>Olen lugenud ja nõustun Krediidivalve OÜ <a href={'/tingimused'} target={'blank'} style={{color: '#492392'}}>üld- ja maksetingimuste</a> ning <a href={'/menetluse-põhimõtted'} target={'blank'} style={{color: '#492392'}}>menetlus-</a> ja <a href={'/hinnastamise-põhimõtted'} target={'blank'} style={{color: '#492392'}}>hinnastamispõhimõtetega</a>.*</span>}/>
                        <div className={'application-text'}>
                            Võlamenetluse alustamise makse tasumisega kinnitate, olles vajadusel konsulteerinud spetsialistiga, et sisestatud andmed on õiged ja soovite sõlmida lepingu Krediidivalve OÜ-ga võlamenetluse algatamiseks.
                        </div>
                        <button className={'btn application-button'} disabled={isSubmitting} type={'submit'}>
                            MAKSAN JA ALUSTAN MENETLUST
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    )
};

export default connect(null, {push})(Application);
