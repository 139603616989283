import React, {useEffect} from 'react';
import './ApplicationSuccess.css';
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {getJsonValue, getPermJsonValue} from "../../store/session-storage-util";
import {useCookies} from "react-cookie";

const ApplicationSuccess = () => {

    const [cookies] = useCookies();

    const csrfToken = () => {
        return cookies["XSRF-TOKEN"];
    };

    useEffect(() => {
        const debtor = getJsonValue('debtorForm') || {person: {}, address: {}, contacts: {}};
        const extraParams = getJsonValue('extraParams') || {};
        const reporter = getPermJsonValue('reporterForm') || getJsonValue('reporterForm') || {person: {}, address: {}, contacts: {}};
        let claims = getJsonValue('claimForm') || {claims: []};
        claims.files = getJsonValue('storedFiles');

        fetch('/api/application', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-XSRF-TOKEN': csrfToken()
                        },
                        body: JSON.stringify({debtor, reporter, claims: claims.claims, claimFiles: claims.files, ...extraParams}),
                        credentials: 'include'
                    }).then(() => console.log("submitted"))
    }, [])


    return (
        <div>
            <div className={'subpage-header'}>TÄNAME TEID</div>
            <div className={'success-subheader'}>Alustasite võlameneltust võlgniku vastu.</div>
            <div className={'success-text'}>Sisestatud võlamenetluse taotlus ning menetluse alustamise tasu maksekinnitus on
                edastatud Teie e-posti aadressile. Kliendilepingu allkirjastamiseks võtab Krediidivalve
                esindaja Teiega ühendust esimesel võimalusel.<br/><br/>

                Kiireloomuliste lisaküsimuste korral võtke meiega ühendust tel: <a style={{color: '#393d3f'}} href={'tel:+372-655-0001'}>+372 655 0001.</a></div>
            <button className={'btn forward-button subpage-bottom-button'} type={'button'} onClick={() => {
                push('/')
            }}>
                AVALEHELE
            </button>
        </div>
    )
};

export default connect(null, {push})(ApplicationSuccess);
