import React from 'react';
import './Checkout.css';
import {connect} from "react-redux";
import {push} from "connected-react-router";
import {ProgressBar} from "../progress-bar/ProgressBar";
import {useCookies} from "react-cookie";
import card from "../../asset/icon-card.svg";
import swed from "../../asset/swed icon.svg";
import seb from "../../asset/seb icon.svg";
import lhv from "../../asset/lhv icon.svg";
import coop from "../../asset/coop icon.svg";
import luminor from "../../asset/luminor icon.svg";
import poco from "../../asset/Pocopay.svg";
import visa from "../../asset/Visa.svg";
import master from "../../asset/Mastercard.svg";
import {getJsonValue, getPermJsonValue} from "../../store/session-storage-util";

const Checkout = () => {

    const [cookies] = useCookies()

    const csrfToken = () => {
        return cookies["XSRF-TOKEN"];
    };

    const makePayment = (bankName) => {
        const reporter = getPermJsonValue('reporterForm') || getJsonValue('reporterForm') || {person: {}, address: {}, contacts: {}};

        fetch(`/api/payment?customerEmail=${reporter.representative.email}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': csrfToken()
            },
            credentials: 'include'
        }).then(response => response.json()).then(json => {
            const banklink = json.payment_methods.banklinks.find((bl) => bl.name === bankName);
            const cardLink = json.payment_methods.cards.find((c) => c.name === bankName);
            if (banklink) {
                window.location.href = banklink.url;
            } else if (cardLink) {
                window.location.href = cardLink.url;
            }
        });
    };

    return (
        <div>
            <ProgressBar index={4}/>
            <div className={'checkout-header'}><img alt={''} src={card}/>MAKSMINE</div>
            <div className={'checkout-text'}>Võlamenetluse alustamise tasu</div>
            <div className={'checkout-sum'}>24.40 €</div>
            <div className={'checkout-tax'}>sisaldab 22% käibemaksu</div>
            <div className={'checkout-text'}>Vali makseviis. Pärast pangalingiga maksmist vajuta
                “Tagasi kaupmehe juurde”.</div>
            <div className={'checkout-links'}>
                <img alt='' className={'checkout-link'} src={seb} onClick={() => makePayment('seb')}/>
                <img alt='' className={'checkout-link'} src={swed} onClick={() => makePayment('swedbank')}/>
                <img alt='' className={'checkout-link'} src={lhv} onClick={() => makePayment('lhv')}/>
                <img alt='' className={'checkout-link'} src={coop} onClick={() => makePayment('coop')}/>
                <img alt='' className={'checkout-link'} src={luminor} onClick={() => makePayment('luminor')}/>
                <img alt='' className={'checkout-link'} src={poco} onClick={() => makePayment('pocopay')}/>
                <div style={{marginTop: '30px'}} />
                <img alt='' className={'checkout-link'} src={visa} onClick={() => makePayment('visa')}/>
                <img alt='' className={'checkout-link'} src={master} onClick={() => makePayment('mastercard')}/>
            </div>
        </div>
    )
};

export default connect(null, {push})(Checkout);
