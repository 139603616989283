import React from 'react';
import './App.css';
import headerCircle from "../../asset/header-circle.png";
import DebtorForm from "../business-form/DebtorForm";

const App = () => {
    return (
        <>
            <div className={'header-section'}>
                <div className={'main-title'}>
                    PERSONAALSELT & PROFESSIONAALSELT
                </div>
                <div className={'main-subtitle'}>
                    Võlamenetluse alustamiseks Eestis:
                </div>
                <div>
                    <ul className={'main-bullets'}>
                        <li><span className={'main-bullets-text'}>täida allolev taotlus</span></li>
                        <li><span className={'main-bullets-text'}>maksa stardiraha</span></li>
                        <li><span className={'main-bullets-text'}>algata menetlus</span></li>
                    </ul>
                </div>
                <img alt='' src={headerCircle}
                     className="main-extra"/>
            </div>
            <div>
                <DebtorForm />
            </div>
        </>
    );
};

export default App;
