import React from 'react';
import './Contacts.css'
import {connect} from "react-redux";
import {push} from "connected-react-router";

const Contacts = ({push}) => {
    return (
        <>
            <div className={'subpage-header'}>VÕTA MEIEGA ÜHENDUST</div>
            <div className={'contacts-info'}>
                Krediidivalve infokeskus suhtleb Teiega E-R vahemikul 9.00-17.00.
            </div>
            <div className={'contacts-text-wrap'}>
                <span className={'contacts-text-header'}>Telefon:</span>
                <a className={'contacts-text'} href={'tel:+372-655-0001'}>+372 655 0001</a>
            </div>
            <div className={'contacts-text-wrap'}>
                <span className={'contacts-text-header'}>E-Mail:</span>
                <a className={'contacts-text'} href={'mailto:info@krediidivalve.ee'} target={"_top"}>info@krediidivalve.ee</a>
            </div>
            <div className={'contacts-text-wrap'}>
                <span className={'contacts-text-header'}>Menetlus:</span>
                <a className={'contacts-text'} href={'mailto:menetlus@krediidivalve.ee'} target={"_top"}>menetlus@krediidivalve.ee</a>
            </div>
            <hr className={'contacts-separator'}/>
            <div className={'contacts-text-wrap'}>
                <span className={'contacts-text-header'}>Ärinimi:</span>
                <span className={'contacts-text'} style={{textDecoration: "none"}}>KREDIIDIVALVE OÜ</span>
            </div>
            <div className={'contacts-text-wrap'}>
                <span className={'contacts-text-header'}>Registrikood:</span>
                <span className={'contacts-text'} style={{textDecoration: "none"}}>12797541</span>
            </div>
            <div className={'contacts-text-wrap'}>
                <span className={'contacts-text-header'}>Postiaadress:</span>
                <a className={'contacts-text'} href={'https://www.google.com/maps/place/Hariduse+13,+10119+Tallinn'} target={'_blank'} rel="noopener noreferrer">Hariduse 13-1a 10119 Tallinn</a>
            </div>
            <button className={'btn forward-button subpage-bottom-button'} type={'button'} onClick={() => push('/')}>
                ALUSTAN MENETLUST
            </button>
        </>
    )
};

export default connect(null, {push})(Contacts);
