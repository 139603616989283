import React from 'react';
import './Creditor.css';
import {connect} from "react-redux";
import {push} from "connected-react-router";

const Creditor = ({push}) => {
    return (
        <div>
            <div className={'subpage-header'}>VÕLAUSALDAJALE</div>
            <div className={'creditor-text'}>
                Krediidivalve on probleemkrediidi juhtimise ja inkasso ettevõte, mille eesmärk on olla kindel partner Eestis tegutsevale võlausaldajale. Krediidivalve haldurid muudavad probleemkrediidi juhtimise tulemuslikuks ja kuluefektiivseks seejuures austades protsessis osalejate õigusi ning järgides seadust.
                <br/>
                <br/>
                Meie jaoks on oluline, et võlavaidluse osapooled saaksid lihtsasti meiega ühendust võtta ning suhtlus oleks viisakas ja tulemuslik. Krediidivalve teenused hõlmavad probleemkrediidi juhtimise etappe alates meeldetuletuse edastamisest kuni kohtujärgse sissenõudmisteenuseni välja. Protsessi etapid toetavad üksteist ja teenivad üheskoos sama eesmärki, tagada tulemuslik probleemkrediidi juhtimine vähima vajaliku aja- ja finantsressursiga. Kaardistame probleemkrediidi juhtumite põhjused, erisused ja kitsaskohad ning leiame parima lahenduse.
                <br/>
                <br/>
                Krediidivalve kohtuvälise sissenõudmismenetluse algatamine Eestis näeb ette stardiraha, mis katab inkassomenetluse alustamisega seotud esmased kulud. Kõik kohtuvälise võlgnevuse sissenõudmisega seotud kulud, vastavalt seaduses sätestatud piirmääradele, lisame võlanõudele seadusjärgsete sissenõudmiskuludena ning nõuame sisse võlgnikult. Eduka sissenõudmise tulemustasu on 20% tagasisaadud summalt. Kohtulik ja kohtujärgne sissenõudmisteenus vastavalt Krediidivalve hinnakirjale.
                <br/>
                <br/>
                Krediidivalve infokeskus suhtleb Teiega tööpäevadel kella 9.00-17.00. Helista: <a style={{color: '#393d3f'}} href={'tel:+372-655-0001'}>+372 655 0001.</a> või kirjuta <a style={{color: '#393d3f'}} href={'mailto:info@krediidivalve.ee'} target={"_top"}>info@krediidivalve.ee</a>
                <br/>
                <br/>
                Krediidivalve - personaalselt ja professionaalselt.
                <div className={'creditor-links'}>
                    <a className={'creditor-link'} href={'/hinnastamise-põhimõtted'}>HINNASTAMISE PÕHIMÕTTED</a>
                    <a className={'creditor-link'} href={'/menetluse-põhimõtted'}>MENETLUSE PÕHIMÕTTED</a>
                </div>
            </div>
            <button className={'btn forward-button subpage-bottom-button'} type={'button'} onClick={() => push('/')}>
                ALUSTAN MENETLUST
            </button>
        </div>
    )
};

export default connect(null, {push})(Creditor);
