import React, {useState} from 'react';
import menu from "../../asset/Icon menu.svg";
import close from "../../asset/icon close.svg";
import {slide as Menu} from "react-burger-menu";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import Logo from "../../layouts/Logo";

const SidebarMenu = ({push}) => {
    const MenuItem = ({children, path, className}) => {
      return (
          <div className={className} onClick={() => {
              setOpen(false);
              push(path);
          }}>{children}</div>
      )
    };

    const [isOpen, setOpen] = useState(false);

    return (
        <div className={'page-header'}>
            <div className={'header-container'}>
                <Logo/>
                <img alt={''} className={'menu-button'} src={menu} onClick={() => setOpen(true)}/>
            </div>
            <Menu
                customBurgerIcon={false}
                customCrossIcon={<img alt={''} src={close} />}
                isOpen={isOpen}
                outerContainerId={'root'}
                onStateChange={(state) => setOpen(state.isOpen)}
                pageWrapId={'App'}
                right
            >
                <MenuItem id={'home'} path={'/'}>AVALEHT</MenuItem>
                <MenuItem id={'creditor'} path={'/võlausaldajale'}>VÕLAUSALDAJALE</MenuItem>
                <MenuItem id={'debtor'} path={'/astu-esimene-samm'}>VÕLGNIKULE</MenuItem>
                <MenuItem id={'contact'} path={'/kontakt'}>KONTAKT</MenuItem>
                <MenuItem className={'secondary-item'} id={'pricing'} path={'/hinnastamise-põhimõtted'}>HINNASTAMISE PÕHIMÕTTED</MenuItem>
                <MenuItem className={'secondary-item'} id={'proceeding'} path={'/menetluse-põhimõtted'}>MENETLUSE PÕHIMÕTTED</MenuItem>
                <MenuItem className={'secondary-item'} id={'terms'} path={'/tingimused'}>ÜLD- JA MAKSETINGIMUSED</MenuItem>
                <MenuItem className={'secondary-item'} id={'gdpr'} path={'/andmekaitsetingimused'}>ANDMEKAITSETINGIMUSED</MenuItem>
            </Menu>
        </div>
    )
};

export default connect(null, { push })(SidebarMenu);
