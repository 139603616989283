import React from 'react';
import {Form, Formik} from "formik";
import {CheckBoxField, DateField, SelectField, SimpleField} from "../form/simple-field";
import {connect} from "react-redux";
import {push} from "connected-react-router";
import './Debtor.css'
import {useCookies} from "react-cookie";

const Debtor = ({push}) => {
    const [cookies] = useCookies();

    const csrfToken = () => {
        return cookies["XSRF-TOKEN"];
    };

  return (
      <div>
          <div className={'subpage-header'}>ASTU ESIMENE SAMM</div>
          <Formik
              initialValues={{
                  contactPhone: '+372 '
              }}
              onSubmit={(values) => {
                  fetch('/api/first-step', {
                      method: 'POST',
                      headers: {
                          'Content-Type': 'application/json',
                          'X-XSRF-TOKEN': csrfToken()
                      },
                      body: JSON.stringify(values),
                      credentials: 'include'
                  }).then(() => push("/esimene-samm-tehtud"))
              }}
          >
              {({
                    values, isSubmitting, setFieldValue
                }) => (
                  <Form>
                      <SimpleField name={'fileNr'} label={'Toimiku number*'} placeholder={'K200000001'} required/>
                      <SimpleField name={'contactPhone'} label={'Kontakttelefon*'} type={'tel'} required/>
                      <SimpleField name={'contactEmail'} label={'Teie kehtiv e-mail*'} type={'email'} required/>
                      <CheckBoxField name={'extraInformation'} type={'checkbox'} label={'Soovin võlainfot'} style={{marginTop: '20px'}}/>
                      <CheckBoxField name={'payDebt'} type={'checkbox'} label={'Soovin võlga tasuda'} style={{marginTop: 0}}/>
                      <CheckBoxField name={'schedule'} type={'checkbox'} label={'Soovin maksegraafikut'} style={{marginTop: 0, marginBottom: '20px'}}/>
                      {
                          values.schedule &&
                              <>
                                  <DateField disabled={!values.schedule} name={'fromDate'} label={'Mis kuupäevast alates?*'} required type={'date'} value={values.fromDate} onChange={setFieldValue} minDate={new Date()}/>
                                  <SimpleField disabled={!values.schedule} name={'periods'} label={'Mitmes osas?*'} required={values.schedule} type={'number'}/>
                              </>
                      }
                      <button className={'btn forward-button'} type={'submit'} disabled={isSubmitting}>
                          SAADA
                      </button>
                  </Form>
              )}
          </Formik>
      </div>
  )
};

export default connect(null, { push })(Debtor);
